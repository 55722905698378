import React from 'react'
import './Contact.css'
import Section from '../section/Section'
import { Bounce } from 'react-reveal'
import ContactForm from '../contactForm/ContactForm'
import { FaTwitter, FaTelegramPlane } from "react-icons/fa";
import { SiLinktree } from "react-icons/si";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const Contact = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  return (
    <Section title="Contact">
      <ContactForm />
      <Bounce cascade>
        <div className="links">
          
          <a
            href="https://twitter.com/Quark_Hadron"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter style={{ fontSize: "50px", color: '#08a0e9' }} />
          </a>

          <a
            href="https://linktr.ee/quarkhadron"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiLinktree style={{ fontSize: "50px", color: '#11998e' }} />
          </a>

          <a
            href="https://t.me/Quark_Hadron"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegramPlane style={{ fontSize: "50px", color: '#fff' }} />
          </a>

        </div>
      </Bounce>
    </Section>
  )
}

export default Contact
