import React from 'react'
import './About.css'
import Typewriter from 'typewriter-effect'
import { Link } from 'react-scroll'
import { Fade, HeadShake } from 'react-reveal'
import Section from '../section/Section'
//import Skills from '../skills/Skills'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DiHtml5, DiCss3, DiJavascript, DiNodejsSmall, DiReact, DiGit, DiMongodb, DiMysql, DiPhp } from "react-icons/di";
import { SiVuedotjs, SiExpress, SiVercel } from "react-icons/si";

const About = () => {
  return (
    <Section title="About">
      <div className="about-content" style={{ marginBottom: "50px" }}>
        <Fade duration={1000}>
          <div className="about-text">
            <h2>Who am I?</h2>
            <p>
              I'm <Link
                className="textLink"
                to="https://twitter.com/Quark_Hadron"
                spy={true}
                smooth={true}
                duration={500}
              >
                Quark Hadron
              </Link>, You can call me Quark{' '}
              <span role="img" aria-label="lightning">
                😄
              </span>{' '}
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              I'm a computer engineer graduate focusing on becoming Full-Stack Web Developer and learning new
              tech such as Blockchain, Cryptocurrencies and NFTs.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              I am currently working with JavaScript, Node.js, React.js, Vue.js and blockchain-related stuff like Smart Contract, DApps and Generative Art.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              I am also a Discord Architect, Server Auditor (focusing on security) and Bot Developer.
            </p>
            <div className="typewriter" >
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                I love
              </p>
              <Typewriter
                options={{
                  strings: [
                    'learning new technologies.',
                    'building discord bots.',
                    'playing board games.',
                    'watching movies.',
                    'trying new things.',
                    'collaborating with others.',
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
            {/* <p>
              Since when I was a kid, I've spent my time seeking and learning new
              technologies. This has led to me
              working on some amazing world-class{' '}
              <Link
                className="textLink"
                to="projects"
                spy={true}
                smooth={true}
                duration={500}
              >
                projects
              </Link>
              , worked at some{' '}
              <Link
                className="textLink"
                to="experience"
                spy={true}
                smooth={true}
                duration={500}
              >
                amazing places
              </Link>
              , and worked with some great people.
            </p> */}
            {/* <div className="location-wrapper">
              <svg
                className="octicon octicon-location"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z"
                ></path>
              </svg>
              <p>Sydney, Australia</p>
            </div> */}

          </div>
        </Fade>
        {/*
        <h2>Skills</h2>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>

            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiHtml5 style={{ fontSize: "80px", color: "#e34c26" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Fade top>
                <DiCss3 style={{ fontSize: "80px", color: "#109dd9" }} />
              </Fade>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiJavascript style={{ fontSize: "80px", color: "#f0db4f" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiPhp style={{ fontSize: "80px", color: "#0868AC" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiMysql style={{ fontSize: "80px", color: "#00758F" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiMongodb style={{ fontSize: "80px", color: "#2f8819" }} />
              </HeadShake>
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiNodejsSmall style={{ fontSize: "80px", color: "#68A063" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiReact style={{ fontSize: "80px", color: "#61DBFB" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <SiVuedotjs style={{ fontSize: "70px", color: "#41B883" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <SiExpress style={{ fontSize: "70px", color: "#000" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiGit style={{ fontSize: "80px", color: "#f1502f" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <SiVercel style={{ fontSize: "70px", color: "#000" }} />
              </HeadShake>
            </Grid>
          </Grid>
        </Box>
          */}
        {/* <Skills /> */}
      </div>
    </Section>
  )
}

export default About
