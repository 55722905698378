import React, { useRef } from 'react'
import './Skills.css'
import IsVisible from 'react-is-visible'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import { useContainerDimensions } from '../../hooks';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DiHtml5, DiCss3, DiJavascript, DiNodejsSmall, DiReact, DiGit, DiMongodb, DiMysql, DiPhp, DiDocker } from "react-icons/di";
import { SiVuedotjs, SiExpress, SiVercel, SiDigitalocean, SiFirebase, SiDiscord, SiNuxtdotjs } from "react-icons/si";
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


const Skills = () => {
  const skillsWrapper = useRef()
  //const { width } = useContainerDimensions(skillsWrapper)

  return (
    <Fade duration={1000}>
      <Section title="Skills">
        <div className="skills-content">
          <Box sx={{ flexGrow: 1, marginBottom: 5 }}>

            {/* <Typography variant="h5" component="h5" sx={{ marginBottom: 3}}>
              Web Development
            </Typography> */}
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >

              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="HTML5">
                    <DiHtml5 style={{ fontSize: "80px", color: "#e34c26" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="CSS3">
                    <DiCss3 style={{ fontSize: "80px", color: "#109dd9" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Javascript">
                    <DiJavascript style={{ fontSize: "80px", color: "#f0db4f" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="PHP">
                    <DiPhp style={{ fontSize: "80px", color: "#0868AC" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="MYSQL">
                    <DiMysql style={{ fontSize: "80px", color: "#00758F" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="MongoDB">
                    <DiMongodb style={{ fontSize: "80px", color: "#2f8819" }} />
                  </Tooltip>
                </Fade>
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Node.js">
                    <DiNodejsSmall style={{ fontSize: "80px", color: "#68A063" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="React.js">
                    <DiReact style={{ fontSize: "80px", color: "#61DBFB" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Vue.js">
                    <SiVuedotjs style={{ fontSize: "70px", color: "#41B883" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Nuxt.js">
                    <SiNuxtdotjs style={{ fontSize: "70px", color: "#41B883" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Express.js">
                    <SiExpress style={{ fontSize: "70px", color: "#000" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Git">
                    <DiGit style={{ fontSize: "80px", color: "#f1502f" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Docker">
                    <DiDocker style={{ fontSize: "80px", color: "#0db7ed" }} />
                  </Tooltip>
                </Fade>
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Vercel">
                    <SiVercel style={{ fontSize: "70px", color: "#000" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="DigitalOcean">
                    <SiDigitalocean style={{ fontSize: "70px", color: "#008bcf" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Firebase">
                    <SiFirebase style={{ fontSize: "70px", color: "#FFA000" }} />
                  </Tooltip>
                </Fade>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Fade>
                  <Tooltip title="Discord">
                    <SiDiscord style={{ fontSize: "70px", color: "#7289da" }} />
                  </Tooltip>
                </Fade>
              </Grid>

            </Grid>

          </Box>
        </div>

      </Section>

    </Fade>
  )
}

export default Skills
