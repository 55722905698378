import React from 'react'
import './Projects.css'
import Typewriter from 'typewriter-effect'
import { Link } from 'react-scroll'
import { Fade, HeadShake } from 'react-reveal'
import Section from '../section/Section'
//import Skills from '../skills/Skills'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DiHtml5, DiCss3, DiJavascript, DiNodejsSmall, DiReact, DiGit, DiMongodb, DiMysql, DiPhp } from "react-icons/di";
import { SiVuedotjs, SiExpress, SiVercel } from "react-icons/si";
import WidgetBot from '@widgetbot/react-embed'

const Projects = () => {
  return (
    <Section title="Projects">
      <div className="about-content" style={{ marginBottom: "50px" }}>
        
        <Fade duration={1000}>
          <WidgetBot
            server="1070702329432649749"
            channel="1070707676528517230"
            width={'80vw'}
            height={'80vh'}
          />
        </Fade>
        {/*
        <h2>Skills</h2>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>

            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiHtml5 style={{ fontSize: "80px", color: "#e34c26" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Fade top>
                <DiCss3 style={{ fontSize: "80px", color: "#109dd9" }} />
              </Fade>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiJavascript style={{ fontSize: "80px", color: "#f0db4f" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiPhp style={{ fontSize: "80px", color: "#0868AC" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiMysql style={{ fontSize: "80px", color: "#00758F" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiMongodb style={{ fontSize: "80px", color: "#2f8819" }} />
              </HeadShake>
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiNodejsSmall style={{ fontSize: "80px", color: "#68A063" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiReact style={{ fontSize: "80px", color: "#61DBFB" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <SiVuedotjs style={{ fontSize: "70px", color: "#41B883" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <SiExpress style={{ fontSize: "70px", color: "#000" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <DiGit style={{ fontSize: "80px", color: "#f1502f" }} />
              </HeadShake>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <HeadShake>
                <SiVercel style={{ fontSize: "70px", color: "#000" }} />
              </HeadShake>
            </Grid>
          </Grid>
        </Box>
          */}
        {/* <Skills /> */}
      </div>
    </Section>
  )
}

export default Projects
